.checkbox-item [type="checkbox"] {
  @apply border-1.5 border-gray-400 rounded ring-transparent;

  &:hover {
    @apply border-gray-700;
  }

  &:disabled {
    @apply bg-none border-gray-400 bg-gray-300;
  }
}

.checkbox-item.gray  {
  & [type="checkbox"] {
    &:checked {
      @apply border-gray-800 bg-gray-800;

      background-image: url('../../assets/images/default/input-check-white.svg');
      background-repeat: no-repeat;

      &:disabled {
        @apply border-gray-400 bg-gray-300;

        &:hover {
          @apply border-gray-400 bg-gray-300;
        }
      }

      &:hover {
        @apply border-gray-900 bg-gray-900;

        background-image: url('../../assets/images/default/input-check-white.svg');
        background-repeat: no-repeat;
      }
    }
  }

  & .hint-text {
    @apply text-gray-800;
  }
}

.checkbox-item.blue {
  @apply text-slate-800;

  & [type="checkbox"] {
    @apply border-slate-400;

    &:hover {
      @apply border-blue-300 bg-white;

      background-image: none;
    }

    &:disabled {
      @apply border-slate-300 bg-slate-50;
    }

    &:checked {
      @apply border-blue-600 bg-blue-600;

      background-image: url('../../assets/images/default/input-check-white.svg');
      background-repeat: no-repeat;

      &:disabled {
        @apply border-slate-300 bg-slate-50;

        background-image: url('../../assets/images/default/input-check-slate.svg');
      }
    }
  }

  & .hint-text {
    @apply text-slate-700;
  }
}

.checkbox-item.multiselect [type="checkbox"] {
  &:checked {
    background-image: url('../../assets/images/default/input-multiselect.svg');
    background-repeat: no-repeat;

    &:hover {
      background-image: url('../../assets/images/default/input-multiselect.svg');
      background-repeat: no-repeat;
    }
  }
}

.radio-button-item [type="radio"] {
  @apply bg-none border-none h-3 w-3 ring-1 ring-offset-2 ring-gray-400 mx-0.5;

  &:hover {
    @apply bg-gray-700 ring-gray-700;
  }

  &:disabled {
    @apply bg-gray-300 ring-offset-gray-300 ring-gray-400;
  }
}

.radio-button-item.gray  {
  & [type="radio"] {
    &:checked {
      @apply bg-gray-900 ring-gray-900;

      &:disabled {
        @apply bg-gray-300 ring-offset-white ring-gray-400;
      }
    }
  }

  & .hint-text {
    @apply text-gray-800;
  }
}

.radio-button-item.purple {
  @apply text-slate-800;

  & [type="radio"] {
    @apply bg-white ring-slate-400;

    &:hover {
      @apply bg-white ring-purple-300;
    }

    &:checked {
      @apply bg-purple-600 ring-purple-600;

      &:disabled {
        @apply bg-slate-200 ring-offset-white ring-slate-200;
      }
    }

    &:disabled {
      @apply bg-slate-25 ring-offset-slate-25 ring-slate-200;
    }
  }

  & .hint-text {
    @apply text-slate-700;
  }
}
